import React from 'react';
import { graphql } from 'gatsby';

import Home from '../components/SecondaryHome';
import Layout from '../components/Layout';
import Seo from '../components/Seo';

class ChordIndex extends React.Component {
  render() {
    const { data, location } = this.props;
    const siteTitle = data.site.siteMetadata.title; // TODO:
    const songs = data.allMarkdownRemark.edges;

    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="ChordSL.com" />
        <Home songs={songs} />
      </Layout>
    );
  }
}

export default ChordIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___title], order: DESC }
      filter: { frontmatter: { layout: { eq: "chord" } } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            artist
          }
        }
      }
    }
  }
`;
